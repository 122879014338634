<template>
  <div>
    <error-disp v-model="error"/>
    <!-- CUSTOMER DETAILS -->
    <v-row v-if="customer" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card class="elevation-4">
          <v-card-title class="primary white--text">
            <v-icon :class="customer.flag?'red--text':''" color="white" large class="pr-3">mdi-account</v-icon>
            <span v-if="customer.is_corporate==true" class="pt-0 pb-1">{{ customer.company_name }}{{customer.is_contractor ? ' (Contractor)' : ''}}</span>
            <span :class="customer.flag?'red--text':''" v-else>{{ customer.salutation }} {{ customer.first_name }} {{ customer.middle_name }} {{ customer.last_name }}</span>
            <v-spacer/>
            <v-switch
              dense
              dark
              hide-details
              :disabled="!flagEnabled()"
              :color="customer.flag ? 'red':'white'"
              class="mb-0 pt-0 pb-0 mt-1 mr-7"
              v-model="customer.flag"
              label="Flagged customer?"
              @click="flagged()"
            />
            <span>Customer no. {{customer.customer_id}}</span>
          </v-card-title>
          <v-card-subtitle v-if="customer.is_corporate==true" class="primary white--text" >
            <span>{{ customer.salutation }} {{ customer.first_name }}  {{ customer.middle_name }} {{ customer.last_name }}</span>
          </v-card-subtitle>
          <v-card-text class="mt-5 ml-3 text-body-1">
            <v-row>
              <v-col cols="12" sm="6" md="4" class="pt-1 pb-0">
                <div><b> Address</b></div>
                <div>{{ customer.address1 }}</div>
                <div>{{ customer.address2 }}</div>
                <div>{{ customer.address3 }}</div>
                <div>{{ customer.country }}</div>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="pt-1 pb-0">
                <div v-if="customer.tax_id">Tax Id: <b>{{customer.tax_id}}</b></div>
                <div v-if="customer.id_no">Id No: <b>{{ customer.id_no }}</b></div>
                <div v-if="customer.phone1">Phone 1: <b>{{ customer.phone1 }}</b></div>
                <div v-if="customer.phone2">Phone 2: <b>{{ customer.phone2 }}</b></div>
                <div v-if="customer.email">Email: <b><a :href="'mailto:' + customer.email">{{ customer.email }}</a></b></div>
                <div v-if="customer.no_marketing_email" class="caption"><b>NO MARKETING EMAIL</b></div>
                <div v-if="customer.no_transaction_email" class="caption"><b>NO TRANSACTION EMAIL</b></div>
                <div v-if="customer.legacy_id">Legacy Id: <b>{{ customer.legacy_id }}</b></div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="d-flex">
                <v-spacer />
                <v-btn
                  class="mr-4"
                  color="primary"
                  v-on:click="$router.push({ name: 'Edit Customer', params: { customer_id: customer.customer_id }})"
                  v-if="$userCheckPermission('PERM_EDIT')"
                >
                Edit
                </v-btn>
                <v-btn
                  class="mr-4"
                  color="primary"
                  v-on:click="deleteCustomer"
                  :disabled="deleteDisabled"
                  v-if="$userCheckPermission('PERM_EDIT')"
                >
                Delete
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-if="customer" cols="12" class="pt-5 pb-0 d-flex flex-wrap justify-end align-content-end caption">
                <span><b>Created:&nbsp;</b>{{formatT(customer.created_at)}}</span>
                <span><b>,&nbsp;Updated:&nbsp;</b>{{formatT(customer.updated_at)}}</span>
                <span>
                  <b>, by&nbsp;</b>
                  <router-link v-if="$userCheckPermission('PERM_USERS_VIEW')" :to="{name: 'User', params: {user_id: customer.updated_by_id}}">{{customer.updated_by}}</router-link>
                  <span v-else>{{customer.updated_by}}</span>
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- STATEMENT -->
    <v-row v-if="$userCheckPermission('PERM_VIEW')" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card>
          <v-card-title class="secondary">
            Statement
            <v-spacer />
            <v-btn
            v-if="false"
              color="primary"
              @click="generateStatement"
            >
              Download
            </v-btn>
          </v-card-title>
          <v-card-text v-if="customer">
            <v-row>
              <v-col>
                <b>Date</b>
              </v-col>
              <v-col>
                <b>Item</b>
              </v-col>
              <v-col>
                <b>Value</b>
              </v-col>
              <v-col>
                <b>Balance</b>
              </v-col>
            </v-row>
            <v-row v-for="(line, i) in statement" :key="i">
              <v-col>
                {{formatT(line.date)}}
              </v-col>
              <v-col>
                <router-link :to="{name: 'Sale', params: { sale_id: line.sale_id }}">{{line.narrative}}</router-link>
              </v-col>
              <v-col>
                {{formatMInt(line.value)}}
              </v-col>
              <v-col>
                {{formatMInt(line.balance)}}
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-body-1"><b>Balance: <span v-if="statement.length > 0">{{ formatMInt(statement[statement.length-1].balance) }}</span><span v-else>{{ formatMInt(0) }}</span></b></span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- SALES -->
    <v-row v-if="customer && $userCheckPermission('PERM_VIEW')" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card>
          <v-card-title class="secondary">
            Sales
            <v-spacer/>
              <v-btn
                color="primary"
                icon
                title="New Sale"
                @click="$router.push({name: 'Edit Sale', params: {customer_id: customer.customer_id}} )"
                v-if="$userCheckPermission('PERM_EDIT') && !hasDraft && !customer.flag"
              >
              <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
          </v-card-title>
            <v-card-text v-if="customer" class="mt-0 pt-0">
              <v-list v-if="sales.length>0">
                <sale-search-result v-for="sale in sales" :value="sale.sale_id" :key="sale.sale_id" hide-customer />
              </v-list>
              <div v-else>None</div>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- RECEIPTS -->
    <v-row v-if="customer && receipts.length>0 && $userCheckPermission('PERM_VIEW')" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card>
          <v-card-title class="secondary">
            Receipts on Other Customer's Sales
          </v-card-title>
          <v-card-text>
            <v-row v-for="receipt in receipts" :key="receipt.receipt_id">
              <v-col cols="6" sm="4" md="3" class="pt-0 pb-1">
                  <router-link :to="{name: 'Sale', params: {sale_id: receipt.sale_id}}">
                    Sale {{ receipt.sale_id }}
                  </router-link>
              </v-col>
              <v-col cols="6" sm="4" md="2" class="pt-0 pb-1"><span>{{formatM(receipt.value)}}</span></v-col>
              <v-col cols="6" sm="4" md="2" class="pt-0 pb-1"><span>{{receipt.payment_type}}</span></v-col>
              <v-col cols="2" sm="4" md="2" class="pt-0 pb-0">
                <a v-if="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')" target="_blank" :href="receiptHref(receipt)"><v-icon color="primary" class="pr-3">mdi-download</v-icon>{{receipt.filename}}</a>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- SITES -->
    <v-row v-if="$userCheckPermission('PERM_VIEW') && sites.length>0" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card>
          <v-card-title class="secondary">
            Sites
            <v-spacer />
            <v-alert v-if="success" type="success">{{success}}</v-alert>
            <v-btn
              color="primary"
              text
              @click="emailCertificates"
              v-if="showEmailCerts"
              title="Email customer site certificates"
              :disabled="!enableEmailCerts"
            >
              <v-icon>mdi-email</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="customer">
            <template v-for="(site, indx) in sites">
              <site-details :key="'s' + indx" v-if="site.site_id" hideCustomer v-model="site.site_id"/>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- LEGACY BURIAL -->
    <v-row v-if="$userCheckPermission('PERM_SALES_SUPER') && !hasDraft" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header class="secondary text-h6">
              Admin Actions
            </v-expansion-panel-header>
            <v-expansion-panel-content v-if="customer" class="pt-5">
              <v-row>
                <v-col cols="12">
                  <v-btn
                    class="mr-4 mb-3"
                    color="primary"
                    v-on:click="legacySiteDialog=true"
                  >
                  Add a Legacy Site
                  </v-btn>
                  <v-dialog v-model="legacySiteDialog" persistent max-width="800">
                    <v-card class="elevation-4">
                      <v-card-title class="primary white--text">
                        Add a legacy site
                      </v-card-title>
                      <v-card-text class="pt-3">
                        <v-row class="align-end">
                          <v-col cols="12">
                            <grave-selector :no-grave="true" @selected="siteSelected" :customer-id="customer.customer_id" />
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeLegacySiteDialog()">Cancel</v-btn>
                        <v-btn color="primary" :disabled="!(site_id)" @click="addLegacySite()">Add</v-btn>
                      </v-card-actions>                      
                    </v-card>
                  </v-dialog>                  
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-btn
                    class="mr-4 mb-3"
                    color="primary"
                    v-on:click="$router.push({name: 'New Burial', query: {legacy: true, customer_id: customer.customer_id}} )"
                  >
                  Add a Legacy Burial
                  </v-btn>
                </v-col>
              </v-row>             
            </v-expansion-panel-content>            
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <!-- NOTES -->
    <v-row  v-if="customer" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <notes
          :customerId="customer.customer_id"
          :objects="childObjs"
          :readonly="!$userCheckPermission('PERM_EDIT')"
          :forceAdd="addNote"
          :setFlag="flagWithNote"
          v-on:added="noteAdded"
          :parentObjectId="customer.customer_id"
          parentObjectType="customer"/>
      </v-col>
    </v-row>
    <!-- DOCUEMENTS -->
    <v-row  v-if="customer" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <documents
          :email="customer.email"
          :customerId="customer.customer_id"
          :readonly="!$userCheckPermission('PERM_EDIT')"
          :objects="childObjs"
          :parentObjectId="customer_id"
          parentObjectType="customer"/>
      </v-col>
    </v-row>
    <!-- AUDIT -->
    <v-row  v-if="customer" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <audit parentObjectType="customer" v-bind:parentObjectId="customer.customer_id"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SaleSearchResult from '@/components/sales/SaleSearchResult'
import Documents from '@/components/shared/Documents'
import Audit from '@/components/shared/Audit'
import SiteDetails from '@/components/sites/SiteDetails'
import ErrorDisp from '@/components/shared/Error'
import Notes from '@/components/notes/Notes'
import GraveSelector from '@/components/sites/GraveSelector'

export default {
  name: 'tCustomer',
  components: {
    SaleSearchResult,
    Documents,
    Audit,
    SiteDetails,
    ErrorDisp,
    Notes,
    GraveSelector
  },
  data () {
    return {
      customer: null,
      site_id: null,
      sales: [],
      sites: [],
      burials: [],
      receipts: [],
      statement: [],
      error: null,
      success: null,
      childObjs: null,
      addNote: false,
      flagWithNote: false,
      legacySiteDialog: false
    }
  },
  watch: {
    '$route.params.customer_id': function () {
      this.load()
    }
  },
  computed: {
    deleteDisabled: function () {
      if (!this.customer) { return true }
      if (this.customer.sales_count === 0) { return false }
      return true
    },
    hasDraft: function () {
      if (this.sales.length === 0) { return false }
      return this.sales.some(sale => sale.sale_status_ref === 'DRAFT')
    },
    showEmailCerts: function () {
      if (!this.customer) { return false }
      if (!this.sites) { return false }
      if (!this.$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')) { return false }

      return this.sites.some(s => {
        return s.owner_customer_id === this.customer.customer_id
      })
    },
    enableEmailCerts: function () {
      if (!this.customer) { return false }
      if (!this.customer.email) { return false }
      if (this.customer.no_transaction_email) { return false }
      return true
    },
  },
  mounted () {
    this.load()
  },
  methods: {
    load () {
      let obs = []
      if (this.$route.params.customer_id) {
        this.customer_id = parseInt(this.$route.params.customer_id)
        this.secureGet('/api/v1/customers/' + this.customer_id) // + '?type=items')
          .then(res => {
            this.customer = res.data
            obs = [{parentObjectType: 'customer', parentObjectId: this.customer_id}]
            return Promise.all([
              this.secureGet('/api/v1/customers/' + this.customer_id + '/sales')
                .then(res => {
                  this.sales = res.data
                  this.sales.forEach(sale => {
                    obs.push({parentObjectType: 'sale', parentObjectId: sale.sale_id})
                  })
                }),
              this.secureGet('/api/v1/customers/' + this.customer_id + '/sale_sites')
                .then(res => {
                  this.sites = res.data
                  this.sites.forEach(site => {
                    obs.push({parentObjectType: 'site', parentObjectId: site.site_id})
                    obs.push({parentObjectType: 'sale_site', parentObjectId: site.sale_site_id})
                  })
                }),
              this.secureGet('/api/v1/customers/' + this.customer_id + '/burials')
                .then(res => {
                  this.burials = res.data
                  this.burials.forEach(burial => {
                    obs.push({parentObjectType: 'burial', parentObjectId: burial.burial_id})
                  })
                }),
              this.secureGet('/api/v1/customers/' + this.customer_id + '/statement')
                .then(res => {
                  this.statement = res.data
                }),
              this.secureGet('/api/v1/customers/' + this.customer_id + '/receipts')
                .then(res => {
                  this.receipts = res.data
                })
            ])
          })
          .then(() => {
            this.childObjs = obs
          })
          .catch(err => { this.error = err })
      }
    },
    siteSelected (val) {
      if (val) {
        this.site_id = val.site_id
      }
    },    
    closeLegacySiteDialog () {
      this.legacySiteDialog = false
      this.site_id = null
    },
    addLegacySite() {
      console.log(this.site_id, this.grave_no)
      this.securePost('/api/v1/sale_sites/legacy', {customerId: this.customer_id, siteId: this.site_id})
        .then(() => {
          this.$router.go()
        })
        .catch(err => { this.error = err })      
      
    },
    generateStatement () {

    },
    receiptHref (receipt) {
      return this.$config.API_URL + '/asset/receipt/' + receipt.receipt_id + '/' + receipt.filename
    },
    flagged () {
      if (this.customer.flag) {
        this.flagWithNote = true
        this.addNote = true
      } else {
        this.securePut('/api/v1/customers/' + this.customer.customer_id + '/flag')
          .then(() => {
            return true
          })
          .catch((error) => { this.error = error })
      }
    },
    flagEnabled () {
      if (this.customer.flag) {
        return this.$userCheckPermission('PERM_SALES_SUPER')
      } else {
        return (this.$userCheckPermission('PERM_EDIT') || this.$userCheckPermission('PERM_SALES_SUPER'))
      }
    },
    noteAdded () {
      this.addNote = false
      this.flagWithNote = false
    },
    deleteCustomer () {
      this.secureDelete('/api/v1/customers/' + this.customer_id)
        .then(() => {
          this.$router.push('/customers')
        })
        .catch(err => { this.error = err })
    },
    emailCertificates () {
      this.secureGet('/api/v1/customers/' + this.customer_id + '/email_certificates')
        .then(() => {
          this.success = 'Email sent'
          setTimeout(() => { this.success = null }, 4000)
        })
        .catch(err => { this.error = err })
    }
  }
}
</script>

<style scoped>

</style>
