<template>
  <div>
    <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
    <template>
      <v-card class="elevation-4">
        <v-card-title class="primary white--text pt-2 pb-3">
          <span>Events</span>
          <v-spacer />
          <v-btn icon @click="scheduleDateDown" color="white" v-if="!hideNav">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span>{{ formatD(date) }}</span>
          <v-btn icon @click="scheduleDateUp" color="white" v-if="!hideNav">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <div v-if="events">
            <v-card
              v-for="(day, i) in Object.entries(events)"
              :key="day[0]"
              class="elevation-0 pb-0 pt-0"
            >
              <v-card-title v-if="Object.entries(events).length>1" class="secondary text-h6 pt-3 pb-2">
                <span v-if="i === 0">Today</span
                ><span v-else>{{ formatD(day[0]) }}</span>
              </v-card-title>
              <v-card-text class="pl-4 pr-4 ">
                <div v-if="day[1].length > 0">
                  <v-row v-for="event in day[1]" :key="event.event_id">
                    <v-col cols="12" class="pl-0 pr-0 pb-1 pt-1">
                      <v-card class="">
                        <v-card-text class="text-body-1 pt-2 pb-2">
                          <v-row no-gutters class="pl-4 pt-0">
                            <v-col cols="6" md="2" class="">
                              <b>{{ formatSlot(event.event_time) }}</b>
                            </v-col>
                            <v-col cols="6" md="4" class="">
                              <b>{{ event.event_type }}</b>
                            </v-col>
                            <v-col cols="12" md="6" class="">
                              <site-map-popup :site="event" />
                              <router-link v-if="$userCheckPermission('PERM_VIEW')" :to="{name: 'Site',params: { site_id: event.site_id }}">{{ event.site_ref }}</router-link>
                              <span v-else>{{ event.site_ref }}</span>
                              {{ event.site_type }}
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="pl-4 pt-0">
                            <v-col cols="6" md="6" class="">
                              <span class="pr-3">
                                Customer: <b>
                                <router-link v-if="$userCheckPermission('PERM_VIEW')" :to="{name: 'Customer',params: { customer_id: event.customer_id }}">{{ customerName(event) }}</router-link>
                                <span v-else>{{ customerName(event) }}</span></b>
                              </span>
                            </v-col>
                            <v-col cols="6" md="6" class="">
                              Sale: <b>
                                <router-link v-if="$userCheckPermission('PERM_VIEW')" :to="{ name: 'Sale', params: { sale_id: event.sale_id }}">{{ event.sale_id }}</router-link>
                                <span v-else>{{ event.sale_id }}</span>
                                <span :style="`color:${saleStatusColor(event.sale_status_ref)};`">({{ event.sale_status }})</span></b>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="pl-4 pt-0">
                            <v-col cols="6" md="6" class="">
                              <span class="pr-3">Deceased: <b> {{ event.deceased }}</b></span>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="pl-4 pt-0">
                            <v-col v-if="event.contractor" cols="12" class="">
                              Contractor:<b> {{ event.contractor }}</b>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="pl-4 pb-0">
                            <v-col cols="12" class="">
                              <b>{{ event.description }}</b>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
                <div v-else class="pt-2 text-body-1">
                  No events
                </div>
              </v-card-text>
            </v-card>
          </div>
          <v-skeleton-loader v-else type="article" />
        </v-card-text>
      </v-card>
    </template>
  </div>
</template>
<script>
import { DateTime } from 'luxon'
import SiteMapPopup from '@/components/sites/SiteMapPopup'

// Used on tCalendar.vue only
export default {
  name: 'EventListDay',
  components: {
    SiteMapPopup
  },
  props: {
    value: String,
    refresh: Boolean,
    hideNav: Boolean
  },
  data () {
    return {
      date: null,
      eventCount: null,
      error: null,
      events: null,
      eventDays: null,
      loaded: false
    }
  },
  mounted () {
    if (this.value) {
      this.date = DateTime.fromISO(this.value)
    } else {
      this.date = DateTime.fromISO(this.currentDate())
    }
    this.update()
  },
  methods: {
    update () {
      this.loaded = false
      this.eventDays = null
      this.events = null
      this.eventCount = 0
      const selectedDate = this.date.toISODate()
      this.secureGet('/api/v1/events?from=' + selectedDate)
        .then(res => {
          this.eventDays = res.data
          this.events = {}
          this.events[selectedDate] = []
          this.eventDays.forEach(evt => {
            const dte = DateTime.fromISO(evt.event_time).toISODate()
            if (dte === selectedDate) { this.events[selectedDate].push(evt) }
          })
        })
    },
    customerName (event) {
      return (event.salutation ? event.salutation + ' ' : '') + (event.first_name ? event.first_name + ' ' : '') + (event.middle_name ? event.middle_name + ' ' : '') + (event.last_name ? event.last_name : '')
    }
  },
  watch: {
    value () {
      this.date = DateTime.fromISO(this.value)
      this.update()
    },
    refresh () {
      this.update()
    }
  },
  computed: {
  }
}
</script>
<style scoped></style>
