<template>
  <div>
    <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
    <template>
      <v-card class="elevation-4">
        <v-expansion-panels :value="null" v-model="expandIndex">
          <v-expansion-panel>
            <v-expansion-panel-header class="primary white--text text-h6 pt-3 pb-3">
              <span class="white--text">Events<span v-if="eventDays">: {{ events.length }}</span><span v-else>: -</span></span>
              <template v-slot:actions>
                <v-icon color="white">$expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-data-iterator
                v-if="eventsDaysList"
                :items="eventsDaysList"
                :page="eventsPage"
                :items-per-page="3"
              >
              <template v-slot:default="props">
                <v-card-text class="mt-2">
                  <template v-if="eventsDaysList">
                    <v-card v-for="(item, i) in props.items" :key="i" class="elevation-0 pb-0 pt-0">
                      <v-card-title class="secondary text-h6 pt-3 pb-2">
                        <span v-if="item[0]=== today">Today</span>
                        <span v-else>{{ formatD(item[0]) }}</span>
                      </v-card-title>
                      <v-card-text class="pl-4 pr-4">
                        <div v-if="item[1].length > 0">
                          <v-row v-for="event in item[1]" :key="event.event_id">
                            <v-col cols="12" class="pl-0 pr-0 pb-1 pt-1">
                              <v-card class="">
                                <v-card-text class="text-body-1 pt-2 pb-2">
                                  <v-row no-gutters class="pl-4 pt-0">
                                    <v-col cols="6" md="1" class="">
                                      <b>{{ formatSlot(event.event_time) }}</b>
                                    </v-col>
                                    <v-col cols="6" md="5" class="">{{ event.event_type }}</v-col>
                                    <v-col cols="12" md="6" class="">
                                      <site-map-popup :site="event"/><router-link :to="{name: 'Site',params: {site_id: event.site_id}}">{{ event.site_ref }}</router-link>
                                      {{ event.site_type }}
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters class="pl-4 pt-0">
                                    <v-col cols="6" md="6" class="">
                                      <span class="pr-3">
                                        <b>Customer: </b>
                                        <router-link :to="{name: 'Customer',params: {customer_id: event.customer_id}}">
                                          {{ event.salutation }}
                                          {{ event.first_name }}
                                          {{ event.middle_name }}
                                          {{ event.last_name }}</router-link>
                                      </span>
                                    </v-col>
                                    <v-col cols="6" md="6" class="">
                                      <b>Sale: </b><router-link :to="{name: 'Sale',params: {sale_id: event.sale_id}}">{{ event.sale_id }}</router-link>
                                      <span :style="`color:${saleStatusColor(event.sale_status_ref)};`">({{ event.sale_status }})</span>
                                    </v-col>
                                  </v-row>
                                  <v-row no-gutters class="pl-4 pt-0">
                                    <v-col cols="6" md="6" class=""><span class="pr-3"><b>Deceased: </b> {{ event.deceased }}</span></v-col>
                                    <v-col v-if="event.contractor" cols="12" md="6" class=""><b>Contractor:</b>{{ event.contractor }}</v-col>
                                  </v-row>
                                  <v-row no-gutters class="pl-4 pb-0">
                                    <v-col cols="12" class="">
                                      {{ event.description }}
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-else class="pt-2 text-body-1">
                          No events
                        </div>
                      </v-card-text>
                    </v-card>
                  </template>
                  <v-skeleton-loader v-else type="article" />
                </v-card-text>
              </template>
              </v-data-iterator>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </template>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import SiteMapPopup from '@/components/sites/SiteMapPopup'

// Used in tHome.vue only
export default {
  name: 'EventList',
  components: {
    SiteMapPopup
  },
  props: {
    expand: Boolean
  },
  data () {
    return {
      date: null,
      error: null,
      today: null,
      events: null,
      eventsPage: 1,
      eventDays: null,
      expandIndex: null,
      eventsDaysList: null
    }
  },
  computed: {},
  created () {
    if (this.expand) {
      this.expandIndex = 0
    }
  },
  mounted () {
    this.date = DateTime.fromISO(this.currentDate())
    this.today = DateTime.now().toISODate()
    this.update()
  },
  methods: {
    update () {
      this.$http.secured
        .get('/api/v1/events?from=' + this.today)
        .then(res => {
          this.events = res.data
          this.eventDays = {}
          this.eventDays[this.today] = []
          this.events.forEach(evt => {
            const dte = DateTime.fromISO(evt.event_time).toISODate()
            if (!this.eventDays[dte]) {
              this.eventDays[dte] = []
            }
            this.eventDays[dte].push(evt)
          })
          this.eventsDaysList = Object.entries(this.eventDays)
        })
        .catch(err => {
          this.error = err
        })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
