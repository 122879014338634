<template>
  <div>
    <template v-if="user">
      <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
      <v-row no-gutters>
        <v-col cols="10">
          <v-row no-gutters>
            <v-col cols="12" sm="4" class="">
              <router-link :to="{name: 'User', params: {user_id: user.user_id}}">{{user.last_name}}<span v-if="user.first_name">, {{user.first_name}}</span></router-link>
            </v-col>
            <v-col cols="12" sm="4">
              <span>{{user.email}}</span>
            </v-col>
            <v-col cols="12" sm="4" class="">
              <span v-if="user.phone">Phone: {{user.phone}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="user.department || user.position">
            <v-col cols="12" sm="4" class="">
              <span v-if="user.department">Department: {{user.department}}</span>
            </v-col>
            <v-col cols="12" sm="4">
              <span v-if="user.position">Position: {{user.position}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <span>Last login: {{formatD(user.last_login)}}</span>
            </v-col>
            <v-col cols="12" sm="4">
              {{user.can_login ? '' : 'No login'}}
            </v-col>
          </v-row>
        </v-col>
        <v-col v-if="!NoButtons" cols="2">
          <v-btn
            icon
            :disabled="!$userCheckPermission('PERM_USERS_EDIT')"
            @click="deleteUser()"
            color="primary">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-btn
            icon
            :disabled="!$userCheckPermission('PERM_USERS_EDIT')"
            @click="$router.push({name:'Edit User', params: {user_id: user.user_id}})"
            color="primary">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <v-skeleton-loader v-else type="article"></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: 'UserDetails',
  props: {
    value: Number,
    NoButtons: Boolean
  },
  data () {
    return {
      user: null,
      error: null
    }
  },
  created () {
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newVal) {
        if (newVal == null) return
        this.secureGet('/api/v1/users/' + newVal)
          .then(res => {
            this.user = res.data
          })
          .catch((error) => { this.error = error })
      }
    }
  },
  methods: {
    deleteUser () {
      this.secureDelete('/api/v1/users/' + this.user.user_id)
        .then(() => {
          this.$emit('deleted')
        })
        .catch(err => { this.error = err })
    }
  }
}
</script>

<style scoped>

</style>
