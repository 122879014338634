var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('error-disp',{model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}}),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"11","lg":"9"}},[_c('v-card',{staticClass:"elevation-4"},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.formatD(_vm.reportDate))+" ")]),_c('v-card-text',{staticClass:"text-body-1"},[_c('daily-charts',{attrs:{"year":_vm.reportDate.year,"month":_vm.reportDate.month,"day":_vm.reportDate.day}}),_c('chart-card',{attrs:{"data":_vm.chartSaleCount,"layout":{
                  yaxis: { tickformat:',.3r', title: 'Sales' },
                  height: 200,
                  font: {size: 11},
                  margin: {t: 10, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Monthly sales count.","download-title":"Sales_count"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlySales,"layout":{
                  yaxis: { tickformat:',.3r', title: _vm.$config.MONEY_SYMBOL + ' (000s)' },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Monthly kwacha sales value.","download-title":"Sale_value_kwacha"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlySalesDollar,"layout":{
                  yaxis: { tickformat:',.3r', title: '$ (000s)' },
                  height: 200,
                  font: {size: 11},
                  margin: {t: 10, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Monthly dollar sales value.","download-title":"Sale_value_dollars"}}),_c('chart-card',{attrs:{"data":_vm.chartAvSaleValueKwacha,"layout":{
                  yaxis: { tickformat:',.0f', title: 'K' },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r:200},
                  bargap :0.15, barmode: 'stack'},"caption":"Average kwacha value of each sale by month.","download-title":"Average_sale_Value_Kwacha"}}),_c('chart-card',{attrs:{"data":_vm.chartAvSaleValue,"layout":{
                  yaxis: { tickformat:',.0f', title: '$' },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r:200},
                  bargap :0.15, barmode: 'stack'},"caption":"Average dollar value of each sale by month.","download-title":"Average_sale_Value"}}),_c('chart-card',{attrs:{"data":_vm.chartYearlySalesDollar,"layout":{
                  yaxis: { tickformat:',.3r', title: '$ (000s)' },
                  xaxis: { tickformat:'.0f', dtick: 1 },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r: 200},
                  bargap :0.15, barmode: 'stack'},"caption":"Total annual sales value","download-title":"Annual_sales_value"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlyBurialsGroups,"layout":{
                  yaxis: { tickformat:',.0f' },
                  height: 270,
                  font: {size: 11},
                  margin: {t: 50, b:30},
                  bargap :0.15, barmode: 'stack'},"caption":"Monthly burials by product.","download-title":"Monthly_burials"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlyBurialsGroupsRel,"layout":{
                  yaxis: { tickformat:'.0%' },
                  height: 270,
                  font: {size: 11},
                  margin: {t: 50, b:30},
                  bargap :0.15, barmode: 'stack'},"caption":"Proportion of monthly burials by product.","download-title":"Monthly_burial_proportion"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlyBurialAvs,"layout":{
                  yaxis: { tickformat:',.1f' },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r:200},
                  bargap :0.15, barmode: 'stack'},"caption":"Average number of burials completed per day by month.","download-title":"Average_burials_per_day"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlyCharges,"layout":{
                 yaxis: { tickformat:',.2r', title: '$ (000s)' },
                  height: 240,
                  font: {size: 11},
                  margin: {t: 50, b:30, r:200},
                  bargap :0.15, barmode: 'relative'},"caption":"Monthly revenue by revenue type.","download-title":"MOnthly_revenue_type"}}),_c('chart-card',{attrs:{"data":_vm.chartMonthlyTypeChargesGroups,"layout":{
                  yaxis: { tickformat:'.0%' },
                  height: 270,
                  font: {size: 11},
                  margin: {t: 50, b:30, r:200},
                  bargap :0.15, barmode: 'stack'},"caption":"Monthly revenue split by product.","download-title":"Monthly_product_revenue"}}),_c('chart-card',{attrs:{"data":_vm.chartYearlyBurialsGroups,"layout":{
                  yaxis: { tickformat:',.0f' },
                  xaxis: { tickformat:'.0f', dtick: 1 },
                  height: 300,
                  font: {size: 11},
                  margin: {t: 50, b:30},
                  bargap :0.15, barmode: 'stack'},"caption":"Annual burial count by product.","download-title":"Annual_burials"}}),_c('chart-card',{attrs:{"data":_vm.chartYearlyBurialsGroupsRel,"layout":{
                  yaxis: { tickformat:'.0%' },
                  xaxis: { tickformat:'.0f', dtick: 1 },
                  height: 270,
                  font: {size: 11},
                  margin: {t: 10, b:60},
                  bargap :0.05, barmode: 'stack'},"caption":"Proportion of annual burials by product.","download-title":"Annual_burial_proportion"}}),_c('chart-card',{attrs:{"data":_vm.chartBurialsGroups,"layout":{
                  height: 350,
                  font: {size: 11},
                  margin: {t: 30, b:60}},"caption":"Breakdown of all burials by site type.","download-title":"Burials_breakdown"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }