var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('error-disp',{model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}}),_c('chart-card',{attrs:{"data":_vm.chartDailyCum,"layout":{
          yaxis: { title:'Burials', tickformat:',.0f'},
          height: 240,
          font: {size: 11},
          margin: {l:50, t: 10, b:30}
          },"caption":"Cumulative number of burials for the current month, previous month and same month in the previous year.","download-title":"Cumulative_burials"}}),_c('chart-card',{attrs:{"data":_vm.chartDailyCumSales,"layout":{
          yaxis: { tickformat:',.0f', title: '$000s'},
          height: 240,
          font: {size: 11},
          margin: {l:50, t: 10, b:30}
          },"caption":"Cumulative dollar sales value for all products for the current month, previous month and same month in the previous year.","download-title":"Cumulative_sales"}}),_c('chart-card',{attrs:{"data":_vm.chartDailyBurialsGroups,"layout":{
          yaxis: { tickformat:',.0f' },
          height: 300,
          font: {size: 11},
          margin: {l:50, t: 10, b:30, r: 200},
          bargap :0.15, barmode: 'stack'},"caption":"Daily burials by site type for the previous month.","download-title":"Daily_Burials_by_Type"}}),_c('chart-card',{attrs:{"data":_vm.chartDailySales,"layout":{
          yaxis: { tickformat:',.3r', title: _vm.$config.MONEY_SYMBOL + ' (000s)' },
          height: 240,
          font: {size: 11},
          margin: {l:50, t: 10, b:30, r: 200},
          bargap :0.15, barmode: 'stack'},"caption":"Daily kwacha sales value for the previous month.","download-title":"Daily_Sales_Kwacha"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }