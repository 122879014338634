import { render, staticRenderFns } from "./tCustomer.vue?vue&type=template&id=b6e4ae20&scoped=true"
import script from "./tCustomer.vue?vue&type=script&lang=js"
export * from "./tCustomer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6e4ae20",
  null
  
)

export default component.exports