<template>
  <div>
    <error-disp v-model="error"/>
    <v-row align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card class="elevation-4">
          <v-card-title class="primary white--text">
            Sales Export Batches
          </v-card-title>
          <v-card-text v-if="loaded" class="mt-5 pl-10 mb-0 pb-0 text-body-1">
            <v-row v-for="month in months" :key="month.month_name">
              <v-col cols="12" class="">
                <b>{{ month.year }} - {{ month.month }}</b>
                <template v-if="month.sales_count>0 || month.receipts_count>0" cols="12">
                  Unbatched sales or receipts for period: {{ month.sales_count}} sales, {{ month.receipts_count}} receipts.
                  <v-btn @click="createBatch(month)" text small color="primary" class="white--text">Batch</v-btn>
                </template>
              </v-col>
              <template v-if="month.batches.length>0">
                <v-col cols="12" class="ml-7" v-for="batch in month.batches" :key="batch.batch_name">
                  <b>{{ batch.batch_name }}</b> - {{ batch.sale_count }} sales, {{ batch.receipt_count }} receipts
                  <v-btn @click="exportBatch(batch)" small text color="primary" class="white--text mr-5">Download</v-btn>
                </v-col>
              </template>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ErrorDisp from '@/components/shared/Error'

export default {
  name: 'SalesExport',
  components: {
    ErrorDisp
  },
  data () {
    return {
      error: null,
      months: null,
      loaded: false
    }
  },
  created () {
    this.secureGet('/api/v1/batches_months/')
      .then(res => {
        this.months = res.data
        this.months.shift()
        this.months = this.months.map(v => {
          v.batches = []
          return v
        })
        return this.secureGet('/api/v1/batches/')
      })
      .then(res => {
        res.data.forEach(batch => {
          this.months.filter(v => v.month_name === batch.month_name)[0].batches.push(batch)
        })
        this.loaded = true
      })
      .catch(err => { this.error = err })
  },
  methods: {
    exportBatch (batch) {
      this.$http.securedXLSX.post('/api/v1/batches/excel/' + batch.export_batch_id)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Batch_' + batch.batch_name + '.xlsx') // or any other extension
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => { this.error = error })
    },
    createBatch (month) {
      this.securePost('/api/v1/batches/' + month.year + '/' + month.month)
        .then(res => {
          if (res.data) {
            this.months.filter(v => v.month_name === res.data.month_name)[0].batches.push(res.data)
            this.months.filter(v => v.month_name === res.data.month_name)[0].sales_count = 0
            this.months.filter(v => v.month_name === res.data.month_name)[0].receipts_count = 0
          }
        })
        .catch(err => {
          this.error = err
        })
    }
  }
}
</script>

<style scoped>

</style>
