<template>

  <v-card class="mb-5">
    <error-disp v-model="error"/>
    <v-card-text class="text-body-1 pt-1 pb-1">
      <v-row>
        <v-col cols="12" class="d-flex black--text text-h6 d-flex">
          <span class="">Sale No: <router-link v-if="sale" :to="saleLink()">{{ value }}</router-link><span v-else>{{ value }}</span> <span v-if="sale">({{formatMInt(sale.price)}})</span></span>
          <v-spacer/>
          <template v-if="sale">
          <v-chip
            v-if="sale.authorizations_outstanding"
            class="mr-2"
            color="orange"
          ><span class="text-body-1 font-weight-bold">auth</span></v-chip>
          <v-chip
            v-if="sale.flag"
            class="mr-2"
            color="red"
          ><span class="text-body-1 font-weight-bold">flag</span></v-chip>
        </template>
          <v-chip
            v-if="sale"
            v-bind:color="saleStatusColor(sale.sale_status_ref)"
            @click="$router.push({name: 'Sale', params: {sale_id: sale.sale_id}})"
          >
            <span class="text-body-1 font-weight-bold">{{ sale.sale_status}}</span>
          </v-chip>
          <v-chip
            v-else
            color="grey"
          ><span class="text-body-1 font-weight-bold">sale</span></v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="4" v-if="!hideCustomer" class="pt-0 pb-0">
          <customer-details v-if="sale" v-model="sale.customer_id" />
            <v-skeleton-loader
            v-else
            type="article"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" sm="5" class="pt-0 pb-4">
          <template v-if="sale">
            <div v-if="sale.sale_sites">
              <span v-if="sale.sale_sites.length>0">Sites: <br v-if="sale.sale_sites.length>1" /></span>
              <span v-for="(site, indx) in sale.sale_sites" :key="indx">
                <b>{{site.site_type}}&nbsp;
                <router-link v-if="site.site_id" :to="{name: 'Site', params: {site_id: site.site_id}}">{{site.site_ref}}</router-link>
                <span v-else>(Unsited)</span>
                <br/></b>
              </span>
            </div>
            <div v-if="sale.burials">
              <span v-if="sale.burials.length>0">Burials: <br v-if="sale.burials.length>1"/></span>
              <span v-for="(burial, indx) in sale.burials" :key="indx">
                <b>
                <router-link :to="{name: 'Burial', params: {burial_id: burial.burial_id}}">{{burial.deceased}}</router-link> {{formatD(burial.burial_date)}} <span class="font-weight-bold" :style="`color:${burialStatusColor(burial.burial_status_ref)};`">({{burial.burial_status}})</span><br/>
              </b>
              </span>
            </div>
            <div v-if="sale.events">
              <span v-if="sale.events.length>0">Events: <br v-if="sale.events.length>1"/></span>
              <span v-for="(event, indx) in sale.events" :key="indx">
                {{formatD(event.event_time)}} <router-link :to="{name: 'Site', params: {site_id: event.site_id}}">{{event.site_ref}}</router-link> <span class="font-weight-bold">{{event.event_type}}</span><br/>
              </span>
            </div>
            <div v-if="sale && sale.note_count>0">Notes:<b> {{sale.note_count}}</b></div>
          </template>
          <template v-else>
            <v-skeleton-loader
              type="article"
            ></v-skeleton-loader>
          </template>
        </v-col>
        <v-col v-if="sale" cols="12" sm="3" class="pt-0 pb-0">
            <div>Tariff: <b>{{sale.tariff_name}}</b></div>
            <div>Sale Total: <b>{{formatMInt(sale.price)}}</b></div>
            <div>Receipted: <b>{{formatMInt(sale.price_receipted)}}</b></div>
            <div>Outstanding: <b>{{formatMInt(sale.price_outstanding)}}</b></div>
            <div v-if="sale.sale_status_ref != 'DRAFT'">Ordered: <b>{{ formatD(sale.ordered_at) }}</b></div>
            <div v-if="sale.sale_status_ref == 'ORDERED'">Age: <b>{{sale.order_age}} days</b></div>
            <div v-if="sale.sale_status_ref != 'DRAFT'">
              <document
                cols="12"
                parentObject="sale"
                :readonly="true"
                :download="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')"
                :customerId="sale.customer_id"
                :parentObjectId="sale.sale_id"
                documentPropertyRef="INVOICE"
              />
            </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="sale" cols="12" class="pt-0 pb-0 d-flex flex-wrap justify-end align-content-end caption">
                <span class="caption"><b>Created:&nbsp;</b>{{formatT(sale.created_at)}}</span>
                <span v-if="sale.ordered_at" class="caption"><b>, Ordered:&nbsp;</b>{{formatT(sale.ordered_at)}}</span>
                <span v-if="sale.fulfilled_at" class="caption"><b>, Fulfilled:&nbsp;</b>{{formatT(sale.fulfilled_at)}}</span>
                <span class="caption"><b>,&nbsp;Updated:&nbsp;</b>{{formatT(sale.updated_at)}}</span>
                <span>
                  <b>, by&nbsp;</b>
                  <router-link v-if="$userCheckPermission('PERM_USERS_VIEW')" :to="{name: 'User', params: {user_id: sale.updated_by_id}}">{{sale.updated_by}}</router-link>
                  <span v-else>{{sale.updated_by}}</span>
                </span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

</template>

<script>
import CustomerDetails from '@/components/customer/CustomerDetails'
import ErrorDisp from '@/components/shared/Error'
import Document from '@/components/shared/Document'

export default {
  name: 'SalesSearchResult',
  components: {
    CustomerDetails,
    Document,
    ErrorDisp
  },
  props: {
    value: Number,
    hideCustomer: Boolean
  },
  data () {
    return {
      sale: null,
      error: null
    }
  },
  computed: {
  },
  watch: {
    value: function (val) {
      this.load()
    }
  },
  created () {
    this.load()
  },
  methods: {
    load () {
      this.sale = null
      this.secureGet('/api/v1/sales/' + this.value + '?type=items')
        .then(res => {
          this.sale = res.data
        })
        .catch((error) => { this.error = error })
    },
    saleLink () {
      if (this.sale.sale_status_ref === 'DRAFT') {
        return {name: 'Edit Sale', params: { customer_id: this.sale.customer_id }}
      } else {
        return {name: 'Sale', params: { sale_id: this.sale.sale_id }}
      }
    }
  }
}
</script>

<style scoped>
</style>
