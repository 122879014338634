<template>
<div>
  <v-alert dense v-if="error" type="error">{{ error }}</v-alert>
  <v-card-text v-if="customer" :class="`${smallText ? 'text-body-2' : 'text-body-1' } pb-0 pl-3 pr-0 pt-0`">
    <template v-if="customer.is_corporate">
      <v-row class="mt-0 pt-0">
        <v-col cols="12" class="pt-0 pb-0 pl-0 ">
          <v-icon small class="pr-2">mdi-factory</v-icon>
          <router-link :to="{ name: 'Customer', params: { customer_id: customer.customer_id }}">
          <span class="pt-0 pb-1 pr-5">{{ customer.company_name }}{{customer.is_contractor ? ' (Contractor)' : ''}}</span>
          </router-link>
          <v-btn
            icon
            x-small
            v-if="$userCheckPermission('PERM_EDIT')"
            @click="$router.push({name: 'Edit Customer', params: {customer_id: customer.customer_id}})"
            color="grey"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0 pt-0">
        <v-col cols="12" class="pt-0 pb-0 pl-0 ">
          <span class="pr-3">{{ customer.salutation }} {{ customer.first_name }} {{ customer.middle_name }} {{ customer.last_name }}</span>
        </v-col>
      </v-row>
    </template>
    <v-row v-else class="mt-0 pt-0">
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <v-chip v-if="customer.flag" class="mr-2" color="red"><span class="text-body-1 font-weight-bold">flag</span></v-chip>
        <v-icon v-else small class="pr-2">mdi-account</v-icon>
        <router-link :to="{ name: 'Customer', params: { customer_id: customer.customer_id }}">
        <span :class="`pr-3 ${customer.flag?'':''}`">{{ customer.salutation }} {{ customer.first_name }} {{ customer.middle_name }} {{ customer.last_name }}</span>
        </router-link>
        <v-btn
          icon
          x-small
          v-if="$userCheckPermission('PERM_EDIT')"
          @click="$router.push({name: 'Edit Customer', params: {customer_id: customer.customer_id}})"
          color="grey"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <span class="">{{ customer.address1 }} {{ customer.address2 }} {{ customer.address3 }} {{ customer.country }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <div>{{ customer.phone1 }} {{ customer.phone2 }}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <div><a :href="`mailto: ${customer.email}`">{{ customer.email }}{{ customer.no_marketing_email ? ' NO MARKETING ' : '' }}{{ customer.no_transaction_email ? ' NO TRANSACTION' : '' }}</a></div>
      </v-col>
    </v-row>
    <v-row v-if="customer.id_no">
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <div>ID no. {{customer.id_no}}</div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0 pl-0 ">
        <div>Customer no. {{ customer.customer_id }}</div>
      </v-col>
    </v-row>
  </v-card-text>
  <v-skeleton-loader
    v-else
    type="article"
  ></v-skeleton-loader>
</div>
</template>

<script>
export default {
  name: 'CustomerDetails',
  props: ['value', 'small'],
  data () {
    return {
      customer: null,
      error: null,
      smallText: false
    }
  },
  created () {
    if (this.small === '') { this.smallText = true }
  },
  watch: {
    value: {
      immediate: true,
      handler: function (newVal) {
        if (newVal == null) return
        this.secureGet('/api/v1/customers/' + newVal)
          .then(res => {
            this.customer = res.data
          })
          .catch((error) => { this.error = error })
      }
    }
  }
}
</script>

<style scoped>

</style>
