<template>
  <div>
    <error-disp v-model="error"/>
    <!--SALE-->
    <v-row align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card class="elevation-4">
          <v-card-title class="primary white--text">
            <v-icon color="white" large class="pr-3">mdi-point-of-sale</v-icon>
            <span>Sale&nbsp;no. <span v-if="sale">{{sale.sale_id}}</span></span>
            <v-spacer/>
            <template v-if="sale">
              <v-switch
                dense
                dark
                hide-details
                :disabled="!flagEnabled()"
                :color="sale.flag ? 'red':'white'"
                class="mb-0 pt-0 pb-0 mt-1 mr-7"
                v-model="sale.flag"
                label="Flagged sale?"
                @click="flagged()"
              />
              <v-chip
                v-if="sale"
                v-bind:color="statusColor(sale.sale_status_ref)"
                class="ml-auto mr-2"
              >
                <span class="text-body-1 font-weight-bold">{{sale.sale_status}}</span>
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                color="grey"
              ><span class="text-body-1 font-weight-bold">sale</span></v-chip>
            </template>
          </v-card-title>
          <v-card-text class="text-body-1">
            <template v-if="sale">
              <v-row >
                <v-col cols="12" sm="7" class="ml-3 pb-1">
                  <customer-details v-model="sale.customer_id"/>
                </v-col>
                <v-col cols="12" sm="4" class="ml-3 pb-1">
                  <div>Tariff:<b> {{sale.tariff_name}}</b></div>
                  <div>Order Total:<b> <span v-if="!pending">{{ formatM(sale.price) }}</span></b></div>
                  <div>Receipted:<b> <span v-if="!pending">{{ formatM(sale.price_receipted) }}</span></b></div>
                  <div>Outstanding:<b> <span v-if="!pending">{{ formatM(sale.price_outstanding) }}</span></b></div>
                  <div v-if="sale.sale_status_ref=='ORDERED'">Ordered:<b> <span>{{ formatD(sale.ordered_at) }} ({{sale.order_age}} days)</span></b></div>
                  <div v-if="sale.invoice_no">Invoice No:<b> {{ sale.invoice_no }}</b></div>
                  <div v-if="sale.is_legacy"><b>Legacy sale</b></div>
                  <div v-if="sale.legacy_receipt_nos">Legact receipt nos: <b>{{sale.legacy_receipt_nos}}</b></div>
                  <div>
                    <document
                      cols="12"
                      parentObject="sale"
                      :readonly="true"
                      :download="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')"
                      :customerId="sale.customer_id"
                      :parentObjectId="sale.sale_id"
                      documentPropertyRef="INVOICE"
                    />
                  </div>
                  <div>
                    <document
                      cols="12"
                      parentObject="sale"
                      :readonly="true"
                      :download="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')"
                      :customerId="sale.customer_id"
                      :parentObjectId="sale.sale_id"
                      documentPropertyRef="ORDER"
                    />
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="sale" cols="12" class="pt-0 pb-0 d-flex flex-wrap justify-end align-content-end caption">
                  <span class="caption"><b>Created:&nbsp;</b>{{formatT(sale.created_at)}}</span>
                  <span v-if="sale.ordered_at" class="caption"><b>, Ordered:&nbsp;</b>{{formatT(sale.ordered_at)}}</span>
                  <span v-if="sale.fulfilled_at" class="caption"><b>, Fulfilled:&nbsp;</b>{{formatT(sale.fulfilled_at)}}</span>
                  <span class="caption"><b>,&nbsp;Updated:&nbsp;</b>{{formatT(sale.updated_at)}}</span>
                  <span>
                    <b>, by&nbsp;</b>
                    <router-link v-if="$userCheckPermission('PERM_USERS_VIEW')" :to="{name: 'User', params: {user_id: sale.updated_by_id}}">{{sale.updated_by}}</router-link>
                    <span v-else>{{sale.updated_by}}</span>
                  </span>
                </v-col>
              </v-row>
            </template>
            <v-skeleton-loader
              v-else
              type="article"
            ></v-skeleton-loader>
            <v-row align="center" justify="center">
              <v-col v-if="sale" cols="12" class="pt-0 pb-0">
                <div
                  v-for="(site, ind) in sale.customer_sites"
                  :key="ind">
                  <sale-site
                    :sale="sale"
                    :saleSite="site"
                    readonly
                    :tariff-id="sale.tariff_id" />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--ORDER-->
    <v-row align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card class="elevation-4">
          <v-card-title class="secondary pb-2 mb-5">
            Order
          </v-card-title>
          <v-card-text v-if="lines" class="text-body-1">
            <v-row v-for="line in lines" :key="line.sale_line_id">
              <v-col cols="10" class="pt-2 pb-1">
                {{formatLineParent(line)}} - {{line.line_description}}
              </v-col>
              <v-col cols="2" class="pt-2 pb-1 d-flex justify-end">
                {{formatM(line.line_price)}}
              </v-col>
            </v-row>
            <v-row class="pt-5">
              <v-col cols="12" class="pl-5 pt-0">
                <span class="text-body-1"><b>Order Total: </b><span v-if="!pending">{{formatM(sale.price) }}</span></span>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions v-if="sale" class="text-h6 pb-5 mr-5 ml-5">
            <v-spacer/>
            <v-btn
              :disabled="!canCancel || pending"
              v-if="$userCheckPermission('PERM_SALES_SUPER')"
              class="mr-3"
              color="primary"
              @click="unplaceSale">Cancel order</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!--RECEIPTS-->
    <template v-if="sale">
      <v-row v-if="sale.sale_status_ref!=='DRAFT'" align="center" justify="center">
        <v-col cols="12" md="11" lg="8">
          <v-card class="elevation-4">
            <v-card-title class="secondary pb-2">
              Receipts
              <v-spacer/>
              <v-btn
                v-if="$userCheckPermission('PERM_RECEIPTS') && sale.sale_status_ref=='ORDERED'"
                icon
                title="Add a new receipt for sale."
                color="primary"
                @click="showReceiptForm"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-10 pr-10 text-body-2">
              <template v-if="receipts.length>0">
                <div v-for="(receipt, indx) in receipts" :key="indx">
                  <v-row>
                    <v-col cols="6" sm="4" md="3" class="pl-5 pt-0 pb-1">
                      <v-icon
                        v-if="$userCheckPermission('PERM_SALES_SUPER')"
                        :disabled="pending"
                        @click="deleteReceipt(receipt, indx)"
                      >
                        mdi-delete
                      </v-icon>
                      <span class="ml-3">{{formatD(receipt.receipt_at)}}</span>
                    </v-col>
                      <v-col cols="6" sm="4" md="3" class="pt-0 pb-1">
                        <span v-if="receipt.receipt_customer_id !== receipt.sale_customer_id">
                          <router-link :to="{name: 'Customer', params: {customer_id: receipt.receipt_customer_id}}">
                            {{ receipt.receipt_customer_id }} - {{ receipt.salutation }} {{ receipt.first_name }} {{ receipt.last_name }}
                          </router-link>
                        </span>
                      </v-col>
                      <v-col cols="6" sm="4" md="2" class="pt-0 pb-1"><span>{{formatM(receipt.value)}}</span></v-col>
                      <v-col cols="6" sm="4" md="2" class="pt-0 pb-1"><span>{{receipt.payment_type}}</span></v-col>
                      <v-col cols="2" sm="4" md="2" class="pt-0 pb-0">
                        <a v-if="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')" target="_blank" :href="receiptHref(receipt)"><v-icon color="primary" class="pr-3">mdi-download</v-icon>{{receipt.filename}}</a>
                      </v-col>
                  </v-row>
                  <v-row><v-col cols="12"  class="pt-0 pb-1"><v-divider/></v-col></v-row>
                </div>
                <div v-if="pending">
                  <v-row>
                    <v-col cols="6" sm="4" md="3" class="pl-5 pt-0 pb-1"><v-skeleton-loader type="text"/></v-col>
                    <v-col cols="6" sm="4" md="2" class="pt-0 pb-1"><v-skeleton-loader type="text"/></v-col>
                    <v-col cols="12" sm="4" md="2" class="pt-0 pb-1"><v-skeleton-loader type="text"/></v-col>
                    <v-col cols="12" sm="6" md="4" class="pt-0 pb-0"><v-skeleton-loader type="text"/></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12"  class="pt-0 pb-1">
                    <v-divider/>
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12" class="pl-5 pb-0 pt-4">
                    <span class="text-body-1"><b>Paid: </b><span v-if="!pending">{{formatM(sale.price_receipted)}}</span></span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pl-5 pt-0">
                    <span class="text-body-1"><b>Outstanding: </b><span v-if="!pending">{{formatM(sale.price_outstanding)}}</span></span>
                  </v-col>
                </v-row>
              </template>
              <v-skeleton-loader
                  v-else
                  type="article"
                ></v-skeleton-loader>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <!-- NEW RECEIPT FORM -->
    <v-dialog v-if="sale" v-model="receiptForm" persistent max-width="800">
      <v-card class="elevation-4">
        <v-card-title class="primary white--text">
          Add a new receipt
        </v-card-title>
        <v-card-text class="pt-7">
          <v-row class="">
            <v-col cols="6" class="">
              Receipt to sale customer:
              <customer-details v-model="receiptCustomerId"/>
            </v-col>
            <v-col cols="6" class="">
              Or select a different customer:
              <span class="d-flex">
              <v-text-field
                    dense
                    v-model="newReceiptCustomerId"
                    label="Customer no"
                    :error-messages="newCustomerErrors"
                    class="pr-5"
                  ></v-text-field>
                  <v-btn class="pt-1" color="primary" small :disabled="pending" @click="changeReceiptCustomer">Change</v-btn>
              </span>
            </v-col>
          </v-row>
        </v-card-text>
          <v-card-text class="pt-7">
          <v-row class="">
            <v-col cols="3.2">
              <v-menu
                v-model="menuReceiptDate"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    dense
                    v-model="formatReceiptDate"
                    label="Receipt Date"
                    prepend-icon="mdi-calendar"
                    class="mr-10"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="receiptDatePick"
                  @input="receiptDatePicked"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="3.2" class="">
              <v-text-field
                  class="mr-0"
                  dense
                  v-model="receiptValue"
                  :error-messages="receiptValueErrors"
                  append-outer-icon="mdi-check"
                  @click:append-outer="receiptValue = sale.price_outstanding"
                  label="Value"
                  type="text"
              ></v-text-field>
            </v-col>
            <v-col cols="3.2" class="">
              <v-select
                :items="paymentTypes"
                dense
                :disabled="pending"
                return-object
                item-text="payment_type"
                item-value="payment_type_id"
                v-model="paymentType"
                :error-messages="paymentTypeErrors"
                clearable
                label="Payment Type"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="pending" @click="showReceiptForm">Cancel</v-btn>
          <v-btn class="" color="primary" :disabled="pending" @click="addReceipt">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--ORDER DOCUMENTS-->
    <v-row v-if="sale" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <v-card class="elevation-4">
          <v-card-title class="secondary pb-2">
            Order Documents
          </v-card-title>
          <v-card-text v-if="sale.sale_sites" class="mt-5 text-body-1">
            <template v-for="saleSite in sale.sale_sites">
              <template>
                <v-row :key="'s' + saleSite.sale_site_id" align="center" justify="center">
                  <v-col cols="12">
                    <div v-if="$userCheckPermission('PERM_EDIT')">
                      <a @click="generateAgreement(saleSite)"><v-icon color="primary" class="pr-3">mdi-download</v-icon>Template sale agreement for {{ saleSite.site_type }}</a>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                      <document
                        parentObject="sale_site"
                        :parentObjectId="saleSite.sale_site_id"
                        documentPropertyRef="AGREEMENT"
                        :download="$userCheckPermission('PERM_DOCUMENT_DOWNLOAD')"
                        :customerId="sale.customer_id"
                        v-model="saleSite.agreementDocumentId"
                        :readonly="!$userCheckPermission('PERM_EDIT')"
                        :label="`Replace signed agreement for ${saleSite.site_type}`"/>
                  </v-col>
                </v-row>
                <v-row :key="'s1' + saleSite.sale_site_id">
                  <v-col cols="12"><v-divider /></v-col>
                </v-row>
              </template>
            </template>
            <v-row>
              <v-col cols="12" class="pl-5 pb-0">
                <div v-if="$userCheckPermission('PERM_EDIT')">
                  <a @click="generateOrderInvoice(false)"><v-icon color="primary" class="pr-3">mdi-download</v-icon>Regenerate Order Summary</a>
                  </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pl-5 pb-0">
                <div v-if="$userCheckPermission('PERM_EDIT')">
                  <a @click="generateOrderInvoice(true)"><v-icon color="primary" class="pr-3">mdi-download</v-icon>Regenerate Invoice</a>
                  </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!--NOTES-->
    <v-row  v-if="sale" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <notes
          v-bind:customerId="sale.customer_id"
          v-bind:objects="sale.objects"
          v-bind:readonly="!$userCheckPermission('PERM_EDIT')"
          :forceAdd="addNote"
          :setFlag="flagWithNote"
          v-on:added="noteAdded"
          v-bind:parentObjectId="sale.sale_id"
          parentObjectType="sale"/>
      </v-col>
    </v-row>
    <!--DOCUMENTS-->
    <v-row  v-if="sale" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <documents
          ref="documents"
          v-bind:objects="sale.objects"
          v-bind:email="sale.email"
          v-bind:readonly="!$userCheckPermission('PERM_EDIT')"
          v-bind:customerId="sale.customer_id"
          v-bind:parentObjectId="sale.sale_id"
          parentObjectType="sale"/>
      </v-col>
    </v-row>
    <!--AUDIT-->
    <v-row  v-if="sale" align="center" justify="center">
      <v-col cols="12" md="11" lg="8">
        <audit parentObjectType="sale" v-bind:parentObjectId="sale.sale_id"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomerDetails from '@/components/customer/CustomerDetails'
import SaleSite from '@/components/sales/SaleSite'
import helpers from '@/services/helpers'
import Audit from '@/components/shared/Audit'
import Document from '@/components/shared/Document'
import Documents from '@/components/shared/Documents'
import ErrorDisp from '@/components/shared/Error'
import { required, integer, decimal, minValue } from 'vuelidate/lib/validators'
import Notes from '@/components/notes/Notes'
import { DateTime } from 'luxon'

const lessThanOutstanding = (value, vm) => {
  if (!vm.sale) { return true }
  if (value <= parseFloat(vm.sale.price_outstanding)) {
    return true
  } else {
    return false
  }
}
export default {
  name: 'Sale',
  components: {
    CustomerDetails,
    SaleSite,
    Audit,
    Documents,
    ErrorDisp,
    Document,
    Notes
  },
  data () {
    return {
      error: null,
      sale: null,
      lines: null,
      receiptForm: false,
      receiptDate: DateTime.now().toISO(),
      receiptDatePick: DateTime.now().toISODate(),
      menuReceiptDate: false,
      receiptValue: null,
      paymentType: null,
      receipts: [],
      addNote: false,
      pending: false,
      paymentTypes: null,
      receiptCustomerId: null,
      newReceiptCustomerId: null,
      flagWithNote: false
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    '$route.params.sale_id': function () {
      this.load()
    }
  },
  validations: {
    receiptValue: {
      required,
      decimal,
      minValue: minValue(0),
      lessThanOutstanding
    },
    newReceiptCustomerId: {
      minValue: minValue(0),
      integer
    },
    paymentType: { required }
  },
  computed: {
    colors: helpers.statusColors,
    formatReceiptDate () {
      if (this.receiptDate) {
        return this.formatD(this.receiptDate)
      } else {
        return ''
      }
    },
    newCustomerErrors () {
      const errors = []
      if (!this.$v.newReceiptCustomerId.$dirty) return errors
      !this.$v.newReceiptCustomerId.integer && errors.push('Customer No must be a number.')
      !this.$v.newReceiptCustomerId.minValue && errors.push('Customer No must be greater than 0.')
      return errors
    },
    receiptValueErrors () {
      const errors = []
      if (!this.$v.receiptValue.$dirty) return errors
      !this.$v.receiptValue.required && errors.push('Receipt value is required.')
      !this.$v.receiptValue.decimal && errors.push('Receipt value must be a number.')
      !this.$v.receiptValue.minValue && errors.push('Receipt value must be greater than 0.')
      !this.$v.receiptValue.lessThanOutstanding && errors.push('Receipt value must be less than the outstanding value.')
      return errors
    },
    paymentTypeErrors () {
      const errors = []
      if (!this.$v.paymentType.$dirty) return errors
      !this.$v.paymentType.required && errors.push('Payment type is required.')
      return errors
    },
    canCancel () {
      if (this.sale) {
        if (this.sale.price === 0 && this.receipts.length == 0) { return true }
        if (this.sale.sale_status_ref !== 'ORDERED') { return false }
        if (!this.receipts) { return false }
        if (this.receipts.length > 0) { return false }
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    // Data
    load () {
      if (this.$route.params.sale_id) {
        // Sale details
        this.secureGet('/api/v1/sales/' + parseInt(this.$route.params.sale_id) + '?type=items')
          .then(res => {
            this.sale = res.data
            this.receiptCustomerId = this.sale.customer_id

            // If this is a DRAFT sale then this is the wrong page
            if (this.sale.sale_status_ref === 'DRAFT') {
              this.$router.push({name: 'Edit Sale', params: { customer_id: this.sale.customer_id }})
            }

            // Get receipts
            this.secureGet('/api/v1/sales/' + this.sale.sale_id + '/receipts')
              .then(res => {
                this.receipts = res.data
              })
          })
          .then(() => {
            return this.reloadLines()
          })
          .catch((error) => { this.error = error })
      }

      this.secureGet('/api/v1/payment_types')
        .then(res => {
          this.paymentTypes = res.data
        })
        
    },
    reloadLines () {
      return this.secureGet('/api/v1/sales/' + this.sale.sale_id + '/lines')
        .then(res => {
          this.lines = res.data
        })
        .catch((error) => { this.error = error })
    },
    // Actions
    flagged () {
      if (this.sale.flag) {
        this.flagWithNote = true
        this.addNote = true
      } else {
        this.securePut('/api/v1/sales/' + this.sale.sale_id + '/flag')
          .then(() => {
            return true
          })
          .catch((error) => { this.error = error })
      }
    },
    flagEnabled () {
      if (this.sale.flag) {
        return this.$userCheckPermission('PERM_SALES_SUPER')
      } else {
        return (this.$userCheckPermission('PERM_EDIT') || this.$userCheckPermission('PERM_SALES_SUPER'))
      }
    },
    noteAdded () {
      this.addNote = false
      this.flagWithNote = false
    },
    generateOrderInvoice (isInvoice) {
      this.securePost(
        '/api/v1/sales/' + this.sale.sale_id + `/generate_${isInvoice ? 'invoice' : 'order'}`, {user_id: JSON.parse(localStorage.user).user_id})
        .then(response => {
          const url = this.$config.API_URL + '/asset/sale/' + this.sale.sale_id + '/' + this.sale.sale_id + (isInvoice ? '_Invoice.pdf' : '_Order_Summary.pdf')
          window.open(url, '_blank')
          this.$refs.documents.reloadDocuments()
        })
        .catch((error) => { this.error = error })
    },
    generateAgreement (saleSite) {
      return this.securePost(
        '/api/v1/sale_sites/' + saleSite.sale_site_id + '/generate_agreement', {user_id: JSON.parse(localStorage.user).user_id})
        .then(response => {
          const url = this.$config.API_URL + '/asset/sale_site/' + saleSite.sale_site_id + '/' + saleSite.site_ref + '_Unsigned_Sale_Agreement.pdf'
          window.open(url, '_blank')
          this.$refs.documents.reloadDocuments()
        })
        .catch((error) => { this.error = error })
    },
    unplaceSale () {
      this.pending = true
      this.securePost('/api/v1/sales/' + this.sale.sale_id + '/unplace')
        .then(() => {
          this.$router.replace({name: 'Edit Sale', params: {customer_id: this.sale.customer_id}})
        })
        .catch((error) => { this.error = 'Error deleting sale. ' + error })
    },
    changeReceiptCustomer () {
      if (this.newReceiptCustomerId) {
        this.$v.newReceiptCustomerId.$touch()
        if (!this.$v.newReceiptCustomerId.$invalid) {
          this.pending = true
          this.secureGet('/api/v1/customers/' + this.newReceiptCustomerId)
            .then(res => {
              this.receiptCustomerId = res.data.customer_id
              this.pending = false
            })
            .catch(() => {
              this.newReceiptCustomerId = null
              this.pending = false
            })
        }
      }
    },
    showReceiptForm () {
      this.receiptForm = !this.receiptForm
      this.receiptDate = DateTime.now().toISO()
      this.receiptDatePick = DateTime.now().toISODate()
      this.menuReceiptDate = false
      this.receiptValue = null
      this.paymentType = null
      this.receiptCustomerId = this.sale.customer_id
      this.newReceiptCustomerId = null
      this.$v.$reset()
    },
    addReceipt () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.pending = true

        this.securePost('/api/v1/sales/' + this.sale.sale_id + '/receipt', {customerId: this.receiptCustomerId, receiptDate: this.receiptDate, receiptValue: this.receiptValue, paymentTypeId: this.paymentType.payment_type_id})//, {

          .then((res) => {
            if (res.data.sale_status_ref !== this.sale.sale_status_ref) {
              this.$router.go()
            }
            this.receipts.push(res.data)
            this.sale.price_receipted = parseFloat(this.sale.price_receipted) + parseFloat(this.receiptValue)
            this.sale.price_outstanding = parseFloat(this.sale.price_outstanding) - parseFloat(this.receiptValue)
            this.receiptValue = null
            this.paymentType = null
            this.pending = false
            this.receiptForm = false
            this.newReceiptCustomerId = null
            this.receiptCustomerId = this.sale.customer_id
            this.$v.$reset()
          })
          .catch((error) => {
            this.error = error
            this.pending = false
          })
      }
    },
    deleteReceipt (receipt, index) {
      this.pending = true
      this.receipts.splice(index, 1)
      this.secureDelete('/api/v1/sales/' + this.sale.sale_id + '/receipts/' + receipt.receipt_id)
        .then((res) => {
          this.sale.price_receipted = parseFloat(this.sale.price_receipted) - parseFloat(receipt.value)
          this.sale.price_outstanding = parseFloat(this.sale.price_outstanding) + parseFloat(receipt.value)
          this.pending = false
          if (res.data.sale_status_ref !== this.sale.sale_status_ref) {
            this.$router.go()
          }
        })
        .catch((error) => { this.error = error })
    },
    receiptDatePicked () {
      this.receiptDate = this.receiptDatePick
      this.menuReceiptDate = false
    },
    // Helpers
    receiptHref (receipt) {
      return this.$config.API_URL + '/asset/receipt/' + receipt.receipt_id + '/' + receipt.filename
    },
    formatLineParent (line) {
      switch (line.parent_object) {
        case 'sale':
          return 'Sale'
        case 'sale_site':
          let saleSite = null
          this.sale.sale_sites.forEach(ste => {
            if (ste.sale_site_id === line.parent_object_id) { saleSite = ste }
          })
          if (saleSite.site_id) {
            return 'Site - ' + saleSite.site_ref
          } else {
            return 'Site - ' + saleSite.site_type
          }
        case 'event':
          let event = null
          this.sale.events.forEach(evt => {
            if (evt.event_id === line.parent_object_id) { event = evt }
          })
          return 'Event - ' + event.event_type + ' ' + this.formatT(event.event_time)
        case 'burial':
          let burial = null
          this.sale.burials.forEach(bur => {
            if (bur.burial_id === line.parent_object_id) { burial = bur }
          })
          return 'Burial - ' + burial.deceased
      }
    },
    statusColor (ref) {
      switch (ref) {
        case 'DRAFT':
          return helpers.statusColors().A
        case 'ORDERED':
          return helpers.statusColors().B
        case 'PAID':
          return helpers.statusColors().C
        case 'FULFILLED':
          return helpers.statusColors().D
      }
    }
  }
}
</script>

<style scoped>

</style>
